import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

const firebaseConfig = {
    "projectId": "roster-19808",
    "appId": "1:420985706143:web:e77e8fd13c6ee9cb0774aa",
    "databaseURL": "https://roster-19808-default-rtdb.europe-west1.firebasedatabase.app",
    "storageBucket": "roster-19808.appspot.com",
    "apiKey": "AIzaSyAMnXjmhXXCBW7KdVoB4z4BuPotYZfaQoM",
    "authDomain": "roster-19808.firebaseapp.com",
    "messagingSenderId": "420985706143"
};

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth;
export const db = firebase.database();