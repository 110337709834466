import React from "react";

function DeleteButton(props) {
    return (
        <button type="button" className="delete" aria-label="Delete" onClick={props.onClick}>
            <span aria-hidden="true">&times;</span>
        </button>
    );
}

export default DeleteButton;