import './style.scss';
import ReactDOM from 'react-dom';
import React from "react";
import Roster from './components/Roster';
import WeekSelector from './components/WeekSelector';
import {auth, db} from './services/firebase';

class App extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            authenticated: false,
            error: null,
            loaded: false
        };
        this.authUnsub = null;
        this.dbRef = null;
    }

    defaultState = () => ({members: [], roles: [], week: 1})

    loadState() {
        this.dbRef = db.ref();
        this.dbRef.on('value', this.handleNewState, (err) => this.setState({error: err.message}));
    }

    saveState() {
        this.dbRef.set({
            members: this.state.members,
            roles: this.state.roles,
            week: this.state.week
        }).catch((reason) => this.setState({error: reason.message}));
    }

    handleNewState = (snapshot) => {
        const state = {...this.defaultState(), ...snapshot.val(), loaded: true};
        this.setState(state);
    };

    doAuth = () => {
        let provider = new auth.GoogleAuthProvider();
        auth().signInWithRedirect(provider).catch((reason) => this.setState({error: reason.message}));
    }

    componentDidMount() {
        this.authUnsub = auth().onAuthStateChanged((user) => {
            if (user) {
                this.setState({authenticated: true});
                this.loadState();
            } else {
                // First, check for a redirect result w/ error
                auth().getRedirectResult().catch((reason) => {
                    // Error occurred
                    this.setState({error: reason.message});
                }).then(() => {
                    // Should only be reached if there was no redirect
                    this.doAuth();
                })
            }
        });
    }

    componentWillUnmount() {
        if (this.authUnsub) {
            this.authUnsub()
            this.authUnsub = null;
        }
        if (this.dbRef) {
            this.dbRef.off('value', this.handleNewState);
            this.dbRef = null;
        }
    }

    componentDidUpdate = (oldProps, oldState) => {
        // Don't save data on the initial load
        if (!oldState.loaded) {
            return;
        }
        if (oldState.members !== this.state.members || oldState.roles !== this.state.roles || oldState.week !== this.state.week) {
            this.saveState()
        }
    }

    handleWeekChange = (num) => this.setState({week: num});
    handleMemberAdd = (name) => this.setState((oldState) => ({members: oldState.members.concat(name)}));
    handleRoleAdd = (name) => this.setState((oldState) => ({roles: oldState.roles.concat(name)}));
    handleMemberDelete = (idx) => this.setState((oldState) => ({members: oldState.members.filter((_, i) => i !== idx)}));
    handleRoleDelete = (idx) => this.setState((oldState) => ({roles: oldState.roles.filter((_, i) => i !== idx)}));

    render() {
        if (this.state.error || !this.state.authenticated || !this.state.loaded) {
            let content;
            if (this.state.error) {
                content = this.state.authenticated ? (
                    <>
                        <span>Error: {this.state.error}</span>
                        <button onClick={() => window.location.reload()}>Reload</button>
                    </>
                ) : (
                    <>
                        <span>Sign-in error: {this.state.error}</span>
                        <button onClick={this.doAuth}>Retry</button>
                    </>
                );
            } else if (!this.state.authenticated) {
                content = <span>Signing in...</span>;
            } else {
                content = <span>Fetching roster...</span>;
            }

            return (
                <>
                    <div className="row">
                        <div className="col-auto"><h1>Bush Giving Roster</h1></div>
                    </div>
                    <div className="row">
                        <div className="col-auto">
                            {content}
                        </div>
                    </div>
                </>
            )
        }

        return (
            <>
                <div className="row align-items-center">
                    <div className="col-auto mr-auto">
                        <h1>Bush Giving Roster</h1>
                    </div>
                    <div className="col-auto">
                        <WeekSelector week={this.state.week} onChange={this.handleWeekChange}/>
                    </div>
                </div>
                <Roster members={this.state.members} roles={this.state.roles} week={this.state.week}
                        onMemberAdd={this.handleMemberAdd} onMemberDelete={this.handleMemberDelete}
                        onRoleAdd={this.handleRoleAdd} onRoleDelete={this.handleRoleDelete}/>
            </>
        );
    }
}

// ========================================

ReactDOM.render(
    <App/>,
    document.getElementById('root')
);
