import React from "react";
import MersenneTwister from "mersenne-twister";
import DeleteButton from "./Util";

class Adder extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {adding: false, value: ''}
    }

    onAdd = () => {
        this.setState({adding: true, value: ''});
    }

    onChange = (e) => {
        this.setState({value: e.target.value});
    }

    onSave = (e) => {
        e.preventDefault();
        this.props.onAdd(this.state.value);
        this.setState({adding: false, value: ''});
    }

    onCancel = () => {
        this.setState({adding: false, value: ''});
    }
}

class MemberAdder extends Adder {
    render() {
        if (!this.state.adding) {
            return <tr>
                <th scope="row" className="border-0" colSpan="5">
                    <button className="btn btn-primary" onClick={this.onAdd}>+</button>
                </th>
            </tr>
        } else {
            return (
                <tr>
                    <th scope="row" className="border-0" colSpan="5">
                        <div className="form-inline">
                            <form onSubmit={this.onSave}>
                                <input type="text" className="form-control" value={this.state.value} onChange={this.onChange}/>
                                <button type="submit" name="save" className="btn btn-success" onClick={this.onSave}>&#x2713;</button>
                                <button type="button" name="cancel" className="btn btn-danger" onClick={this.onCancel}>&#x2717;</button>
                            </form>
                        </div>
                    </th>
                </tr>
            );
        }
    }

}

class RoleAdder extends Adder {
    render() {
        if (!this.state.adding) {
            return <th className="bg-white border-0" scope="col">
                <button className="btn btn-primary" onClick={this.onAdd}>+</button>
            </th>
        } else {
            return (
                <th className="bg-white border-0 form-inline" scope="col">
                    <form onSubmit={this.onSave}>
                        <input type="text" className="form-control" value={this.state.value} onChange={this.onChange}/>
                        <button type="submit" name="save" className="btn btn-success" onClick={this.onSave}>&#x2713;</button>
                        <button type="button" name="cancel" className="btn btn-danger" onClick={this.onCancel}>&#x2717;</button>
                    </form>
                </th>
            );
        }
    }
}

function RosterColumn(props) {
    return (
        <th scope="col">{props.name}
            &nbsp;<DeleteButton onClick={() => props.onDelete(props.id)} />
        </th>
    );
}

function RosterRow(props) {
    return (
        <tr>
            <th scope="row">{props.name}
                &nbsp;<DeleteButton onClick={() => props.onDelete(props.id)} />
            </th>
            {props.data.map((x) => <td key={x}>{x}</td>)}
        </tr>
    );
}

function Roster(props) {
    const roster = getRoster(props.members, props.roles, props.week);

    return (
        <div className="row table-responsive">
            <table className="table table-striped">
                <thead className="table-dark">
                <tr>
                    <th scope="col">Name</th>
                    {props.roles.map((name, i) => <RosterColumn key={i} id={i} name={name}
                                                                onDelete={props.onRoleDelete}/>)}
                    <RoleAdder onAdd={props.onRoleAdd}/>
                </tr>
                </thead>
                <tbody>
                {props.members.map((name, i) => <RosterRow key={i} id={i} name={name} data={roster[i] || []}
                                                           onDelete={props.onMemberDelete}/>)}
                </tbody>
                <tfoot>
                <MemberAdder onAdd={props.onMemberAdd}/>
                </tfoot>
            </table>
        </div>
    );
}

function getRoster(members, roles, week) {
    if (!members || !roles || members.length <= roles.length) {
        return [];
    }

    const rand = new MersenneTwister(week);
    let toRet = null;
    while (toRet == null) {
        toRet = rollRoster(members, roles, rand);
    }

    return toRet;
}

function rollRoster(members, roles, rand) {
    // Set up the list of candidates for each role
    const memberCount = members.length;
    const roleCount = roles.length;
    let candidates = [];
    let choices = [];
    for (let i = 0; i < roleCount; i++) {
        candidates.push([...members.keys()]);
    }
    for (let i = 0; i < memberCount; i++) {
        choices.push([]);
    }

    // Select our members
    for (let y = 0; y < memberCount; y++) {
        let exclude = [y];
        for (let x = 0; x < roleCount; x++) {
            const chooseFrom = candidates[x].filter(cand => !exclude.includes(cand));
            if (chooseFrom.length === 0) {
                return null;
            }
            const choice = rand.random_int() % chooseFrom.length;
            const member = chooseFrom[choice];
            exclude.push(member);
            candidates[x] = candidates[x].filter(cand => cand !== member);
            choices[y].push(member);
        }
    }

    // Map the indices back to the member names
    return choices.map((arr) => arr.map((i) => members[i]));
}

export default Roster;