import InputSpinner from 'react-bootstrap-input-spinner'

const WeekSelector = (props) => (
    <div className="row g-3 align-items-center">
        <div className="col-auto">
            <span>Week</span>
        </div>
        <div className="col-auto">
            <InputSpinner value={props.week} precision={0} onChange={props.onChange} step={1} type={"int"} arrows={true} />
        </div>
    </div>
);

export default WeekSelector;